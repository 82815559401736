<template>
  <swiper
    :breakpoints="swiperOption.breakpoints"
    :modules="modules"
    :slides-per-view="3"
    :space-between="20"
    :loop="true"
    :autoplay="{
      delay: 2500,
      disableOnInteraction: false,
    }"
  >
    <swiper-slide v-for="(item, index) in parter" :key="index"
      ><img :src="item.image" :alt="item[locale].name"
    /></swiper-slide>
  </swiper>
</template>
<script>
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import CourseService from "@/services/CourseService";
import { mapGetters } from "vuex";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 3,
        breakpoints: {
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          1023: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
        },
      },
      parter: [],
    };
  },
  setup() {
    return {
      modules: [Autoplay],
    };
  },
  computed: {
    ...mapGetters(["locale"]),
  },
  mounted() {
    this.dataParters();
  },
  methods: {
    async dataParters() {
      await CourseService.getParters()
        .then((resp) => {
          this.parter = resp.data.data;
        })
        .catch(() => {});
    },
  },
};
</script>
