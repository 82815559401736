<template>
  <div class="golf-course">
    <div class="golf-course__main flex justify-between">
      <div class="info-left" data-aos="fade-up">
        <div class="item"  v-if="dataTop">
          <div class="item__bg">
            <img src="../assets/img/golfSourse/golf-1.png" alt="" />
          </div>
          <div class="item__content">
            <div class="title">{{dataTop[locale].name}}</div>
            <div class="line"></div>
            <router-link
              class="view"
              :to="'/golf-course/detail'"
              @click="setActive()"
            >
              CHI TIẾT SÂN
            </router-link>
          </div>
        </div>
      </div>
      <div class="info-right">
        <div class="info-right__top" data-aos="fade-up">
          <h5>{{ trans('frontend.welcome') }}</h5>
          <h1 v-if="dataInfo" v-html="dataInfo?.welcome[locale]?.name"></h1>
          <h5 v-if="dataInfo" v-html="dataInfo?.welcome[locale]?.description"></h5>
        </div>
        <div class="info-right__content" data-aos="fade-up">
          <p data-aos="fade-up" data-aos-duration="3000" v-if="dataInfo" v-html="dataInfo?.welcome[locale]?.content">
          </p>
        </div>
        <div class="info-right__slide" data-aos="fade-up">
          <div class="list-ads">
            <SlidePatner />
          </div>
        </div>
        <div class="info-right__bottom" data-aos="fade-up">
          <h4>{{ trans('frontend.managed by') }}</h4>
          <img src="../assets/img/golfSourse/made.svg" alt="" />
        </div>
      </div>
    </div>
    <div class="golf-course__sub flex justify-between" data-aos="fade-up" v-if="dataOther && dataOther.length > 0">
      <div class="item" v-for="(item, index) in dataOther" :key="index">
        <div class="item__bg">
          <img :src="item.image" alt="" />
        </div>
        <div class="item__content">
          <div class="title">{{item[locale].name}}</div>
          <div class="line"></div>
          <div class="view">
            <router-link
              :to="'/golf-course/'+ item.slug"
              @click="setActive()"
            >
              CHI TIẾT SÂN
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SlidePatner from "@/components/golfCourse/SlidePatner.vue";
import CourseService from "@/services/CourseService";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    SlidePatner,
  },
  data() {
    return {
      data: "",
      dataOther: "",
      dataTop: "",
      dataInfo: "",
      headMenu: {
        main: 0,
        sub: true,
      },
    };
  },
  mounted() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    this.setMenu(this.headMenu);
    // this.dataCourse();
    this.dataCourseInfo();
  },
  computed: {
    ...mapGetters(["locale"]),
  },
  methods: {
    ...mapActions(["setMenu"]),
    setActive() {
      this.$store.commit("setMenuActive", 0);
      this.$store.commit("setSubActive", 0);
    },
    // async dataCourse() {
    //   await CourseService.getCourse(1)
    //     .then((resp) => {
    //       this.data = resp.data.data;
    //     })
    //     .catch(() => {});
    // },
    async dataCourseInfo() {
      await CourseService.getCourseInfo()
        .then((resp) => {
          this.dataInfo = resp.data.data;
          this.dataTop = this.dataInfo.courses[0];
          if (this.dataInfo.courses && this.dataInfo.courses.length > 1) {
            this.dataOther = this.dataInfo.courses;
            this.dataOther.shift();
          }
        })
        .catch(() => {});
    },
  },
};
</script>
