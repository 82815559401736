import http from "@/http/http-common";
const resource = "/api/frontend";
class CourseService {
    getParters() {
        return http.get(`${resource}/partners`);
    }
    generalInformationCourse() {
        return http.get(`${resource}/generalInformationCourse`);
    }
    getListHole(id) {
        return http.get(`${resource}/getHolesByCourse?course_id=${id}`);
    }
    getCourse(id) {
        return http.get(`${resource}/detailCourse?id=${id}`);
    }
    getCourseInfo() {
        return http.get(`${resource}/getCourses`);
    }
    detailHole(id) {
        return http.get(`${resource}/detailHole?id=${id}`);
    }
    priceList() {
        return http.get(`${resource}/priceList`);
    }
    getListCaddy() {
        return http.get(`${resource}/caddies`);
    }
    informationCaddy() {
        return http.get(`${resource}/informationCaddy`);
    }

}

export default new CourseService();